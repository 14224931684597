import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Layout from "../components/layoutMainCustom"
import BgImage from "../images/holiday_big.jpg"
import ChristmasEve from "../images/CATM_Square.jpg"
import ChristmasCarnival from "../images/christmasCarnival_2024.jpg"
import { Button, Collapse, Stack, Box } from "@chakra-ui/core"
import SEO from "../components/seo"
import PageHeaders from "../components/pageHeaders"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
`

function IndexPage() {
  const [show, setShow] = React.useState(false)
  const [show1, setShow1] = React.useState(false)
  const [show2, setShow2] = React.useState(false)

  const handleToggle1 = () => setShow1(!show1)
  const handleToggle = () => setShow(!show)
  const handleToggle2 = () => setShow2(!show2)
  {
    return (
      <Layout
        ImgSrc={BgImage}
        MainText="Christmas at Cross Church"
        SecondText="December 6th - December 24th"
      >
        <SEO title="Christmas at Cross Church" />
        <PageHeaders
          css={css`
            display: flex;
            margin-top: 4rem;
          `}
        >
          Christmas at Cross Church
        </PageHeaders>
        <HeaderText></HeaderText>
        <div
          css={css`
            display: flex;
            @media (max-width: 940px) {
              flex-direction: column;
            }
          `}
        >
          <div>
            <ParagraphText>
              This Christmas, Cross Church is offering 9 services across 4
              different campuses. Join us live in-person at{" "}
              <Link to="/surprise">Cross Church Surprise</Link>,{" "}
              <Link to="/cornville">Cross Church Cornville</Link>,{" "}
              <Link to="/phoenix">Cross Church Phoenix</Link>, or{" "}
              <Link to="/elmirage">Cross Church El Mirage</Link>.
            </ParagraphText>
            <HeaderText>Surprise Campus</HeaderText>
            <ParagraphText>
              December 24th @ 1:00pm, 2:30pm, 4:00pm, and 5:30pm
              <br />
              <a href="https://www.facebook.com/share/1DP5RGJmrj/">
                Click here for more info
              </a>
            </ParagraphText>
            <HeaderText>Phoenix Campus</HeaderText>
            <ParagraphText>
              December 24th @ 3:30pm and 5:00pm
              <br />
              <a href="https://www.facebook.com/share/15H1od7ydL/">
                Click here for more info
              </a>
            </ParagraphText>
            <HeaderText>Cornville Campus</HeaderText>
            <ParagraphText>
              December 24th @ 3:00pm, 4:30pm, & 6:00pm
              <br />
              <a href="https://www.facebook.com/share/1GRhMnrxrZ/">
                Click here for more info
              </a>
            </ParagraphText>
            <HeaderText>El Mirage Campus</HeaderText>
            <ParagraphText>
              December 24th @ 2:00pm, 3:30pm, & 5:00pm
              <br />
              <a href="https://www.facebook.com/share/15JsF3KSrc/">
                Click here for more info
              </a>
            </ParagraphText>
          </div>
          <img
            src={ChristmasEve}
            alt="Christmas Eve at Cross Church"
            css={css`
              width: auto;
              height: 400px;
              margin-left: 4rem;
              border-radius: 6px;
              @media (max-width: 940px) {
                width: 100%;
                margin-top: 2rem;
                margin-left: 0;
                height: auto;
              }
            `}
          />
        </div>
        <PageHeaders
          css={css`
            display: flex;
          `}
        >
          Christmas Carnivals
        </PageHeaders>
        <HeaderText></HeaderText>
        <div
          css={css`
            display: flex;
            @media (max-width: 940px) {
              flex-direction: column;
            }
          `}
        >
          <div>
            <ParagraphText>
              Join us for some Christmas season fun at any one of our four
              campuses. Check the links below for more detail.
            </ParagraphText>
            <HeaderText>Cornville Campus</HeaderText>
            <ParagraphText>
              December 6th @ 6pm
              <br />
              <a href="https://www.facebook.com/share/15FQaBdoDg/">
                Click here for more info
              </a>
            </ParagraphText>
            <HeaderText>Phoenix Campus</HeaderText>
            <ParagraphText>
              December 7th @ 4pm
              <br />
              <a href="https://www.facebook.com/share/1VbwqDLsTQ/">
                Click here for more info
              </a>
            </ParagraphText>
            <HeaderText>Surprise Campus</HeaderText>
            <ParagraphText>
              December 8th @ 5pm
              <br />
              <a href="https://www.facebook.com/share/1AcP6MxbRR/">
                Click here for more info
              </a>
            </ParagraphText>
            <HeaderText>El Mirage Campus</HeaderText>
            <ParagraphText>
              December 14th @ 5pm
              <br />
              <a href="https://www.facebook.com/share/14uF15y9Nc/">
                Click here for more info
              </a>
            </ParagraphText>
          </div>
          <img
            src={ChristmasCarnival}
            alt="Christmas Carnivals at Cross Church"
            css={css`
              width: auto;
              height: 110px;
              margin-left: 4rem;
              border-radius: 6px;
              @media (max-width: 940px) {
                width: 100%;
                margin-top: 2rem;
                margin-left: 0;
                height: auto;
              }
            `}
          />
        </div>
      </Layout>
    )
  }
}

export default IndexPage
